/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {


if ($('.race-info-menu ul li').hasClass('active')) {
    $('.race-info').addClass('active-parent');
}
if ($('.registration-info ul li').hasClass('active')) {
    $('.registration-info').addClass('active-parent');
}
if ($('.weekend-info-menu ul li').hasClass('active')) {
    $('.weekend-info').addClass('active-parent');
}
if ($('.community-info-menu ul li').hasClass('active')) {
    $('.community-info').addClass('active-parent');
}
if ($('.media-info-menu ul li').hasClass('active')) {
    $('.media-info').addClass('active-parent');
}

// Mobile Hamburger Menu
(function () {
    $('.hamburger-menu').on('click', function() {
        $(this).toggleClass('open');
        $('.bar').toggleClass('animate');
        $('.navbar').toggleClass('active');
        $('body').toggleClass('open_nav');
    });
    $('#user-profile').on('click', function() {
    if ($('.sidebar').hasClass('active')) {
        $('.bar').toggleClass('animate');
        $('.navbar').toggleClass('active');
    }
    });
})();


$('a[rel="relativeanchor"]').click(function(){
    $('html, body').animate({
        scrollTop: $( $.attr(this, 'href') ).offset().top
    }, 800);
    return false;
});
$('.page-anchor').click(function(){
    $('html, body').animate({
        scrollTop: $( $.attr(this, 'href') ).offset().top - 60
    }, 800);
    return false;
});


$("#scroll-top").hide();
// fade in #back-top
$(function () {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 600) {
            $('#scroll-top').fadeIn();
        } else {
            $('#scroll-top').fadeOut();
        }
    });
    // scroll body to 0px on click
    $('#scroll-top').click(function () {
        $('html, body').animate({
            scrollTop: 0
        }, 800);
        return false;

    });
});

$( '.page-nav' ).fixedsticky();

var slider = $('.info-slider');
slider.slick({
    draggable: false,
    arrows: false,
    autoplay:true,
    dots: false,
    fade: true,
    speed: 1400,
    infinite: true,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    touchThreshold: 100
});

$('.nav-slide').click(function(){
    $('.nav-slide').removeClass('active');
    $(this).addClass('active');
});

$(document).ready(function(){
    $('#go_to_slide_01').on('click',function(){
        slider.slick('slickGoTo', 0);
    });
    $('#go_to_slide_02').on('click',function(){
        slider.slick('slickGoTo', 1);
    });
    $('#go_to_slide_03').on('click',function(){
        slider.slick('slickGoTo', 2);
    });
});





var sponsor_slider = $('.sponsors-slider');
sponsor_slider.slick({
    draggable: true,
    arrows: true,
    prevArrow: "<div class='prev slider-arrow'><i class='material-icons'>keyboard_arrow_left</i></div>",
    nextArrow: "<div class='next slider-arrow'><i class='material-icons'>keyboard_arrow_right</i></div>",
    autoplay:false,
    dots: true,
    fade: false,
    speed: 800,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    touchThreshold: 100,
    responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 544,
      settings: {
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }
  ]
});


$(function(){
    var eventDetails = $('.event-details');
    $(window).scroll(function(){
        if($(window).scrollTop() > 420){
       		eventDetails.addClass('sticky');
        } else {
            eventDetails.removeClass('sticky');
        }
    });
})


// Scroll Reveal
window.sr = ScrollReveal({
    //reset: true,
    opacity: 0,
    scale: 1,
    duration: 1000,
    distance: '100px',
    mobile: false,
    viewFactor: 0.4
});
sr.reveal('.fadein');





// Instafeed
/*
var userFeed = new Instafeed({
  get: 'user',
  userId: '1567514',
    template: '<a href="{{link}}" class="col-xs-4 col-sm-2"><img src="{{image}}" class="img-fluid" /></a>',
  accessToken: '1567514.1677ed0.e5aebe60a3f54194aa36e4ec5fde8c3d',
  resolution: 'standard_resolution',
  limit: 18
});
userFeed.run();
*/


var slickSlider = $('.slider').slick({
    centerMode: false,
    arrows: true,
    prevArrow: "<div class='prev slider-arrow'><i class='material-icons'>keyboard_arrow_left</i></div>",
    nextArrow: "<div class='next slider-arrow'><i class='material-icons'>keyboard_arrow_right</i></div>",
    slidesToShow: 1,
    responsive: [
    {
      breakpoint: 544,
      settings: {
        arrows: false
      }
    }
  ]
});



        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $('.news-slider').slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
            adaptiveHeight: true,
            prevArrow: "<div class='prev slider-arrow'><i class='material-icons'>keyboard_arrow_left</i></div>",
            nextArrow: "<div class='next slider-arrow'><i class='material-icons'>keyboard_arrow_right</i></div>",
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 544,
              settings: {
                  draggable: false,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });


/*
  window.onload=function() {
    // Month,Day,Year,Hour,Minute,Second
    upTime('06,1,2018,14:00:00'); // ****** Change this line!
  }
  function upTime(countTo) {
    now = new Date();
    countTo = new Date(countTo);
    difference = (now-countTo);

    days=Math.floor(difference/(60*60*1000*24)*1);
    hours=Math.floor((difference%(60*60*1000*24))/(60*60*1000)*1);
    mins=Math.floor(((difference%(60*60*1000*24))%(60*60*1000))/(60*1000)*1);
    secs=Math.floor((((difference%(60*60*1000*24))%(60*60*1000))%(60*1000))/1000*1);

    document.getElementById('days').firstChild.nodeValue = days;
    document.getElementById('hours').firstChild.nodeValue = hours;
    document.getElementById('minutes').firstChild.nodeValue = mins;
    document.getElementById('seconds').firstChild.nodeValue = secs;

    clearTimeout(upTime.to);
    upTime.to=setTimeout(function(){ upTime(countTo); },1000);
  }

*/

var the_date = $('#date').html();
// console.log(the_date);

$(function(){
	$(".count-down").countdown(the_date, function(event) {
		// $('.month .number').text(
			// event.strftime('%-m')
		// );
		$('.days .number').text(
			event.strftime('%-D')
		);
		$('.hours .number').text(
			event.strftime('%-H')
		);
		$('.minutes .number').text(
			event.strftime('%-M')
		);
		//$('.seconds .number').text(
		//	event.strftime('%-S')
		// );
	});
})








      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // Virtual tour
    'virtual_tour': {
      init: function() {

  var imgUrls = [];

  function ChangePreviewSrc(index) {
    var imgSrc = imgUrls[index];
    $(".preview").attr("src", imgSrc);
    $(".preview").attr("data-img-id", index);
  }

  function PreviewNav(step) {
    var index = parseInt($(".preview").attr("data-img-id"));
    var last = imgUrls.length - 1;

    switch (step) {
      case "previous":
        if (index <= 0) {
          index = last;
        } else {
          index--;
        }
        break;

      case "next":
        if (index >= last) {
          index = 0;
        } else {
          index++;
        }
    }

    ChangePreviewSrc(index);
  }

  $(".thumbnail").each(function(index) {
    imgUrls.push($(this).attr("src"));
    $(this).attr("data-img-id", index);
  });

  $(".thumbnail").click(function(e) {
      e.preventDefault();
    var index = $(this).attr("data-img-id");
    ChangePreviewSrc(index);
    $(".lightbox").fadeIn();
      $(".lightbox_bg").fadeIn();
  });

  $(".close-gallery").click(function() {
    $(".lightbox").fadeOut();
      $(".lightbox_bg").fadeOut();
  });

  $(".previous").click(function() {
    PreviewNav("previous");
  });

  $(".next").click(function() {
    PreviewNav("next");
  });



            var $timeline_block = $('.cd-timeline-block');

            //hide timeline blocks which are outside the viewport
            $timeline_block.each(function(){
                if($(this).offset().top > $(window).scrollTop()+$(window).height()*0.75) {
                    $(this).find('.cd-timeline-img, .cd-timeline-content').addClass('is-hidden');
                }
            });

            //on scolling, show/animate timeline blocks when enter the viewport
            $(window).on('scroll', function(){
                $timeline_block.each(function(){
                    if( $(this).offset().top <= $(window).scrollTop()+$(window).height()*0.75 && $(this).find('.cd-timeline-img').hasClass('is-hidden') ) {
                        $(this).find('.cd-timeline-img, .cd-timeline-content').removeClass('is-hidden').addClass('bounce-in');
                    }
                });
            });



      }
    },

    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },

    'contact': {
      init: function() {

(function($) {

/*
*  new_map
*
*  This function will render a Google Map onto the selected jQuery element
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$el (jQuery element)
*  @return	n/a
*/

function new_map( $el ) {

	// var
	var $markers = $el.find('.marker');


	// vars
	var args = {
		zoom		: 16,
		center		: new google.maps.LatLng(0, 0),
		mapTypeId	: google.maps.MapTypeId.ROADMAP,
            styles      : [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#46bcec"},{"visibility":"on"}]}]
	};


	// create map
	var map = new google.maps.Map( $el[0], args);


	// add a markers reference
	map.markers = [];


	// add markers
	$markers.each(function(){

    	add_marker( $(this), map );

	});


	// center map
	center_map( map );


	// return
	return map;

}

/*
*  add_marker
*
*  This function will add a marker to the selected Google Map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$marker (jQuery element)
*  @param	map (Google Map object)
*  @return	n/a
*/

function add_marker( $marker, map ) {

	// var
	var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

	// create marker
	var marker = new google.maps.Marker({
		position	: latlng,
		map			: map
	});

	// add to array
	map.markers.push( marker );

	// if marker contains HTML, add it to an infoWindow
	if( $marker.html() )
	{
		// create info window
		var infowindow = new google.maps.InfoWindow({
			content		: $marker.html()
		});

		// show info window when marker is clicked
		google.maps.event.addListener(marker, 'click', function() {

			infowindow.open( map, marker );

		});
	}

}

/*
*  center_map
*
*  This function will center the map, showing all markers attached to this map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	map (Google Map object)
*  @return	n/a
*/

function center_map( map ) {

	// vars
	var bounds = new google.maps.LatLngBounds();

	// loop through all markers and create bounds
	$.each( map.markers, function( i, marker ){

		var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

		bounds.extend( latlng );

	});

	// only 1 marker?
	if( map.markers.length == 1 )
	{
		// set center of map
	    map.setCenter( bounds.getCenter() );
	    map.setZoom( 16 );
	}
	else
	{
		// fit to bounds
		map.fitBounds( bounds );
	}

}

/*
*  document ready
*
*  This function will render each map when the document is ready (page has loaded)
*
*  @type	function
*  @date	8/11/2013
*  @since	5.0.0
*
*  @param	n/a
*  @return	n/a
*/
// global var
var map = null;

$(document).ready(function(){

	$('.contact-map').each(function(){

		// create map
		map = new_map( $(this) );

	});

});

})(jQuery);

      }
    },




    // Race Instructions
    'race_weekend_instructions': {
      init: function() {

        // $('.race-cta').attr('data-race');

        $('.race-cta').click(function() {

            var thisRace = $(this).attr('data-race');
            console.log(thisRace);

            $('.race-content').removeClass('active');
            $('#'+thisRace).addClass('active');

            $('html, body').animate({
                scrollTop: $('#race-overview-content').offset().top - 100
            }, 800);


        });




      }
    },
    // Race Instructions
    'monterey_county_residents': {
      init: function() {

        // $('.race-cta').attr('data-race');




        $("#gate_keeper").submit(function(e){
          e.preventDefault();
          check = $(".zipcode").val();
          zip = ["95004", "95039", "95076","93906", "93940", "93905", "93955", "93933", "93901", "93950", "93907", "93923", "93927", "93960", "93930", "93908", "93924", "95012", "93921", "93953", "93926", "93912", "93451", "93915", "93922", "93942", "93902", "93426", "93920", "93925", "93962", "93932", "93450", "93928", "93944", "93954", "93943"]
          if(jQuery.inArray(check, zip) !== -1){ 
            window.location.replace($('.location').val());
          } else  {
            $(".message").show();
          }

          });



      }
    },
    'single_races': {
      init: function() {

        $('.race-anchor').click(function(){
            $('html, body').animate({
                scrollTop: $( $.attr(this, 'href') ).offset().top - 240
            }, 800);
            return false;
        });

      }
    },
    'drawing_info': {
        init: function() {
            $('.race-anchor').click(function(){ $('html, body').animate({
                scrollTop: $( $.attr(this, 'href') ).offset().top - 240
            }, 800);
            return false; });
        }
    },
    'entry_options': {
        init: function() {
            $('.race-anchor').click(function(){ $('html, body').animate({
                scrollTop: $( $.attr(this, 'href') ).offset().top - 240
            }, 800);
            return false; });
        }
    },
    // Photo Galleries  page
    'single_galleries': {
      init: function() {


  var $container = $('.packery').imagesLoaded(function() {
    $container.packery();
  });

  var imgUrls = [];

  function ChangePreviewSrc(index) {
    var imgSrc = imgUrls[index];
    $(".preview").attr("src", imgSrc);
    $(".preview").attr("data-img-id", index);
  }

  function PreviewNav(step) {
    var index = parseInt($(".preview").attr("data-img-id"));
    var last = imgUrls.length - 1;

    switch (step) {
      case "previous":
        if (index <= 0) {
          index = last;
        } else {
          index--;
        }
        break;

      case "next":
        if (index >= last) {
          index = 0;
        } else {
          index++;
        }
    }

    ChangePreviewSrc(index);
  }

  $(".thumbnail").each(function(index) {
    imgUrls.push($(this).attr("src"));
    $(this).attr("data-img-id", index);
  });

  $(".thumbnail").click(function(e) {
      e.preventDefault();
    var index = $(this).attr("data-img-id");
    ChangePreviewSrc(index);
    $(".lightbox").fadeIn();
      $(".lightbox_bg").fadeIn();
  });
  $(".close-gallery").click(function() {
    $(".lightbox").fadeOut();
      $(".lightbox_bg").fadeOut();
  });

  $(".previous").click(function() {
    PreviewNav("previous");
  });

  $(".next").click(function() {
    PreviewNav("next");
  });



      }
    },



    // Sponsorship Charts
    'our_sponsors': {
      init: function() {


        var sponsor_slider_gold = $('.sponsors-slider-gold');
        sponsor_slider_gold.slick({
            draggable: true,
            arrows: true,
            prevArrow: "<div class='prev slider-arrow'><i class='material-icons'>keyboard_arrow_left</i></div>",
            nextArrow: "<div class='next slider-arrow'><i class='material-icons'>keyboard_arrow_right</i></div>",
            autoplay:false,
            dots: true,
            fade: false,
            speed: 800,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            touchThreshold: 100,
            responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 544,
              settings: {
                arrows: false,
                slidesToShow: 3,
                slidesToScroll: 3
              }
            }
          ]
        });

        var sponsor_slider_silver = $('.sponsors-slider-silver');
        sponsor_slider_silver.slick({
            draggable: true,
            arrows: true,
            prevArrow: "<div class='prev slider-arrow'><i class='material-icons'>keyboard_arrow_left</i></div>",
            nextArrow: "<div class='next slider-arrow'><i class='material-icons'>keyboard_arrow_right</i></div>",
            autoplay:false,
            dots: true,
            fade: false,
            speed: 800,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            touchThreshold: 100,
            responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 544,
              settings: {
                arrows: false,
                slidesToShow: 3,
                slidesToScroll: 3
              }
            }
          ]
        });

        var sponsor_slider_bronze = $('.sponsors-slider-bronze');
        sponsor_slider_bronze.slick({
            draggable: true,
            arrows: true,
            prevArrow: "<div class='prev slider-arrow'><i class='material-icons'>keyboard_arrow_left</i></div>",
            nextArrow: "<div class='next slider-arrow'><i class='material-icons'>keyboard_arrow_right</i></div>",
            autoplay:false,
            dots: true,
            fade: false,
            speed: 800,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            touchThreshold: 100,
            responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 544,
              settings: {
                arrows: false,
                slidesToShow: 3,
                slidesToScroll: 3
              }
            }
          ]
        });


        var sponsor_slider_media = $('.sponsors-slider-media');
        sponsor_slider_media.slick({
            draggable: true,
            arrows: true,
            prevArrow: "<div class='prev slider-arrow'><i class='material-icons'>keyboard_arrow_left</i></div>",
            nextArrow: "<div class='next slider-arrow'><i class='material-icons'>keyboard_arrow_right</i></div>",
            autoplay:false,
            dots: true,
            fade: false,
            speed: 800,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            touchThreshold: 100,
            responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 544,
              settings: {
                arrows: false,
                slidesToShow: 3,
                slidesToScroll: 3
              }
            }
          ]
        });

        var sponsor_slider_directors = $('.sponsors-slider-directors');
        sponsor_slider_directors.slick({
            draggable: true,
            arrows: true,
            prevArrow: "<div class='prev slider-arrow'><i class='material-icons'>keyboard_arrow_left</i></div>",
            nextArrow: "<div class='next slider-arrow'><i class='material-icons'>keyboard_arrow_right</i></div>",
            autoplay:false,
            dots: true,
            fade: false,
            speed: 800,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            touchThreshold: 100,
            responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 544,
              settings: {
                arrows: false,
                slidesToShow: 3,
                slidesToScroll: 3
              }
            }
          ]
        });

        var sponsor_slider_thanks = $('.sponsors-slider-thanks');
        sponsor_slider_thanks.slick({
            draggable: true,
            arrows: true,
            prevArrow: "<div class='prev slider-arrow'><i class='material-icons'>keyboard_arrow_left</i></div>",
            nextArrow: "<div class='next slider-arrow'><i class='material-icons'>keyboard_arrow_right</i></div>",
            autoplay:false,
            dots: true,
            fade: false,
            speed: 800,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            touchThreshold: 100,
            responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 544,
              settings: {
                arrows: false,
                slidesToShow: 3,
                slidesToScroll: 3
              }
            }
          ]
        });






      }
    },

    // Videos
    'videos': {
      init: function() {


            // YOUTUBE PLAYLSIT
            // Load Youtube IFrame Player API code asynchronously. This boat is going nowhere without it.
            var tag = document.createElement('script'); //Add a script tag
            tag.src = "https://www.youtube.com/iframe_api"; //Set the SRC to get the API
            var firstScriptTag = document.getElementsByTagName('script')[0]; //Find the first script tag in the html
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag); //Put this script tag before the first one

            var player; //The Youtube API player
            var ypt_player = document.getElementById('player');
            var playlistID = ypt_player.getAttribute('data-pl');
            var ypt_thumbs = document.getElementById('ypt_thumbs');
            var nowPlaying = "ypt-now-playing"; //For marking the current thumb
            var nowPlayingClass = "." + nowPlaying;
            var ypt_index = 0; //Playlists begin at the first video by default

            function getPlaylistData(playlistID, video_list, page_token) { //Makes a single request to Youtube Data API
              var apiKey = 'AIzaSyArQNfmJDkjxP_ZyZIocbyuDeyTanf4Rl8';
              var theUrl =
              'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet' +
              '&maxResults='+ 20 +
              '&playlistId=' + playlistID +
              '&key=' + apiKey
              ;
              if(page_token){ theUrl +=('&pageToken=' + page_token );} //If there is page token, start there
              var xmlHttp = null;
              xmlHttp = new XMLHttpRequest();
              xmlHttp.open( "GET", theUrl, true);
              xmlHttp.send( null );
              xmlHttp.onload = function (e) { //when the request comes back
                buildJSON(xmlHttp.responseText, video_list, playlistID); //send the data to buildJSON
              };
            }

            function buildJSON(response, list, playlistID){ //Takes the text response and adds it to any existing JSON data
              var results = JSON.parse(response); //Parse it
              if(!list){ list = []; } //If there is no list to add to, make one
              list.push.apply(list,results.items); //Add JSON data to the list
              if(results.nextPageToken){ //If the results included a page token
                getPlaylistData(playlistID, list, results.nextPageToken); //Create another data API request including the current list and page token
              } else { //If there is not a next-page token
                buildHTML(list); //Send the JSON data to buildHTML
              }
            }

            function buildHTML(data){ //Turns JSON data into HTML elements
              var list_data = ''; //A string container
              for(i = 0; i < data.length; i++){ //Do this to each item in the JSON list
                var item = data[i].snippet; //Each Youtube playlist item snippet
                if(!item.thumbnails){continue;} //private videos do no reveal thumbs, so skip them
                list_data += '<div class="col-xs-12 col-sm-6 col-md-4"><div class="vid-wrap" data-ypt-index="'+ i +'"><div class="play-btn"><div class="inner"><i class="ion-ios-play"></i></div></div><img alt="'+ item.title +'" src="'+ item.thumbnails.medium.url +'"/><div class="vid-desc"><p>' + item.title + '</p></div></div></div>'; //create an element and add it to the list
              }
              ypt_thumbs.innerHTML = list_data; //After the for loop, insert that list of links into the html
            }


            function onPlayerReady(event) { //Once the player is ready...
              yptThumbHeight(); //Set the thumb containter height
            }

            getPlaylistData(playlistID);

            //Once the Youtube Iframe API is ready...
            window.onYouTubeIframeAPIReady = function() { // Creates an <iframe> (and YouTube player) after the API code downloads. must be globally available
              player = new YT.Player('player', {
                height: '420',
                width: '760',
                playerVars:
                {
                  listType:'playlist',
                  list: playlistID
                },
                events: {
                  'onReady': onPlayerReady,
                  'onStateChange': onPlayerStateChange
                }
              });

              // When the player does something...
              function onPlayerStateChange(event) {

                //Let's check on what video is playing
                var currentIndex = player.getPlaylistIndex();
                var the_thumbs = ypt_thumbs.getElementsByTagName('li');
                var currentThumb = the_thumbs[currentIndex];

                if (event.data == YT.PlayerState.PLAYING) { //A video is playing

                  for (var i = 0; i < the_thumbs.length; i++) { //Loop through the thumbs
                    the_thumbs[i].className = ""; //Remove nowplaying from each thumb
                  }

                  currentThumb.className = nowPlaying; //this will also erase any other class belonging to the li
                  //need to do a match looking for now playing
                }

                //if a video has finished, and the current index is the last video, and that thumb already has the nowplaying class
                if (event.data == YT.PlayerState.ENDED && currentIndex == the_thumbs.length - 1 && the_thumbs[currentIndex].className == nowPlaying){
                  jQuery.event.trigger('playlistEnd'); //Trigger a global event
                }
              } //function onPlayerStateChange(event)

              //When the user changes the window size...
              window.addEventListener('resize', function(event){
                yptThumbHeight(); //change the height of the thumblist
              });

              //When the user clicks an element with a playlist index...
              jQuery(document).on('click','[data-ypt-index]:not(".ypt-now-playing")',function(e){ //click on a thumb that is not currently playing
                ypt_index = Number(jQuery(this).attr('data-ypt-index')); //Get the ypt_index of the clicked item
                if(navigator.userAgent.match(/(iPad|iPhone|iPod)/g)){ //if IOS
                  player.cuePlaylist({ //cue is required for IOS 7
                    listType: 'playlist',
                    list: playlistID,
                    index: ypt_index,
                    suggestedQuality: 'hd720' //quality is required for cue to work, for now
                    // https://code.google.com/p/gdata-issues/issues/detail?id=5411
                  }); //player.cuePlaylist
                } else { //yay it's not IOS!
                player.playVideoAt(ypt_index); //Play the new video, does not work for IOS 7
              }
              jQuery(nowPlayingClass).removeClass(nowPlaying); //Remove "now playing" from the thumb that is no longer playing

                $('#main_vid').show();
                $('#featured_vid').hide();

                featured_vid
                $('html, body').animate({
                    scrollTop: 0
                }, 800);


              //When the new video starts playing, its thumb will get the now playing class
            }); //jQuery(document).on('click','#ypt_thumbs...
            };



      }
    }


  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
